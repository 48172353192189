<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'tiers'"
          :AllResponse="'alltiers'"
          :headers="headers"
          :field="'DATECREAT'"
          :filename="'Operateurs'"
          :sheetname="'Operateurs'"
          :showedit="false"
          :del_disable="tier.nbtrans > 0"
          @open="OpenTierForm"
          @rowselect="TierChange"
          :Add="true"
          :del="true"
          :filter="false"
          :list_options="false"
          :key="klist"
          :search_elm="filtre"
          v-show="$route.params.tier_id != -1"
          :load="loading"
        >
        </listitemspage>
      </v-col>

      <tierform
        :item="tier"
        :key="ktier"
        :showForm="!isTierClosed"
        :countrys="countrys"
        :modify="editer"
        :valider="valider"
        :currencies="currencies"
        :users="users"
        :modes="modes"
        @close="closeTierForm"
        @cancel="tier = selitem"
        @add="tier_add"
      >
      </tierform>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
import TIERS from "../graphql/Tier/TIERS.gql";
import ALLTIERS from "../graphql/Tier/ALLTIERS.gql";
import DELETE_TIERS from "../graphql/Tier/DELETE_TIER.gql";
import DATA from "../graphql/Tier/DATA.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    tierform: () => import("../views/TierForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {},
  data: () => ({
    selitem: {},
    unfilter: false,
    width: "1000px",
    orientation: "p",
    isTierClosed: true,
    headers: [
      {
        text: "Code",
        align: "start",
        value: "code",
        enum: "CODE",
        selected: true,
        index: 0,
      },
      {
        text: "Nom",
        align: "start",
        value: "nom",
        enum: "NOM",
        slot: "href",
        selected: true,
        index: 1,
      },
      {
        text: "Type",
        align: "start",
        value: "type_tier",
        enum: "TYPE_TIER",
        slot: "chip",
        list: ["F", "C", "Autre"],
        selected: true,
        index: 2,
      },
      {
        text: "Init.",
        align: "end",
        value: "sit_init",
        enum: "SIT_INIT",
        slot: "cur",
        selected: true,
        index: 3,
      },
      {
        text: "Transfere",
        align: "end",
        value: "tot_debit",
        enum: "tot_debit",
        slot: "cur",
        selected: true,
        index: 3,
      },
      {
        text: "Reçu",
        align: "end",
        value: "tot_credit",
        enum: "tot_credit",
        slot: "cur",
        selected: true,
        index: 3,
      },
      {
        text: "Solde",
        align: "end",
        value: "solde",
        enum: "SOLDE",
        slot: "cur",
        selected: true,
        index: 3,
      },
      {
        text: "Transactions",
        align: "end",
        value: "nbtrans",
        enum: "NBTRANS",
        selected: true,
        index: 3,
      },
      {
        text: "Credits",
        align: "end",
        value: "nbcredit",
        enum: "NBCREDIT",
        selected: true,
        index: 3,
      },
    ],
    tab_init: 1,
    form_list: [],
    categories: [],
    cat_tier: [],
    modes: [],
    tiers_list: [],
    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    isFilterClosed: true,
    tier: {},
    loading: false,
    isListClose: true,
    imp_si: false,
    title: "",
    frml: 600,
    countrys: [],
    users: [],
    villes: [],
    btn_save_dis: true,
    editer: false,
    valider: false,
    klist: 0,
    ktier: 100,

    currencies: [],
    alert: false,
    type_alert: "info",
    message: "",
    listheaders: [],

    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    filtre: "",
    year: null,
    route: {},
  }),

  computed: {},
  watch: {
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.Qselect = TIERS;
    this.Qselectall = ALLTIERS;
    this.Qdelete = DELETE_TIERS;
    this.year = this.$store.state.exercice;
    this.editer =
      this.$store.state.auth.includes("02002") || this.$store.state.isadmin;

    this.valider =
      this.$store.state.auth.includes("03002") || this.$store.state.isadmin;
    await this.get_data();
  },

  methods: {
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    tier_add(item) {
      this.tier = item;
      this.selitem = item;
    },

    async maj(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async get_data() {
      this.loading = true;

      let r = await this.requette(DATA);
      if (r) {
        this.countrys = r.countrys;
        this.currencies = r.currencies;
        this.modes = r.modes;
        this.users = r.users;
      }
      //

      this.loading = false;
    },

    async TierChange(item) {
      this.tier = item;
      this.selitem = item;
    },
    closeTierForm() {
      this.isTierClosed = true;
    },
    async OpenTierForm(item) {
      this.tier = item;
      this.isTierClosed = false;
      this.ktier++;
    },
  },
};
</script>
